import React from "react"
import { ServicePageLayout, layoutColorTypes } from "../../../layouts/service-page/service-page.layout"
import { getCanonicalUrl } from "../../../utils/getCanonicalUrl"

import { testimonials } from "./testimonials"
import { getStaticImgUrl } from "../../../utils/getStaticImgUrl"

const content = {
  title: "eBook Writing Services",
  whatWeOffer: {
    leftPart: "eBooks have been growing in popularity ever since they first started showing up. Nowadays, they are used extensively for learning and development as well as for entertainment purposes.",
    rightPart: "If you are looking for eBook Writing Services, your search is over. Here, at Podroom Creative, we have a team of experienced content writers and editors who will take care of everything."
  },
  processText: "The final product will be an eBook that's ready for publishing. Contact us today to get a quote.",
  testimonials: testimonials
}

const meta = {
  title: "eBook Writing Services",
  description: "eBooks have been growing in popularity ever since they first started showing up. Nowadays, they are used extensively for learning and development as well as for entertainment purposes."
}

const og = {
  image: getStaticImgUrl("img_contnet_writing_full.jpg")
}

const pageSlug = "content-services/e-book-writing";

export default () => (

  <ServicePageLayout 

    meta={meta}
    og={og}
    layoutColorType={layoutColorTypes.YELLOW}
    rightHeroImage={"/img_contnet_writing_full.jpg"}
    headerIcon={"/ico_content_writing_2@2x.png"}
    content={content}
    canonicalUrl={getCanonicalUrl(pageSlug)}

  />

)